var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.selectedArea === 'wizard')?[(_setup.selectedIntegration)?[(
          _setup.selectedIntegration.id in (_setup.migrationChecks?.value ?? []) &&
          !_setup.hasOnlyMigration &&
          _setup.hasMigration
        )?_c(_setup.FormHelper,{attrs:{"form":_setup.migrationStatusForm,"config":{
          title: _vm.$t(
            'salesChannelManagementApp.migration.changeMigrationTitle'
          ),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...(_setup.labelData ?? {})
        }},on:{"change":function($event){return _setup.onChangeMigrationStatus($event)}},model:{value:(_setup.migrationStatus),callback:function ($$v) {_setup.migrationStatus=$$v},expression:"migrationStatus"}}):_vm._e(),(
          !_setup.store.getters?.areaBlacklist?.includes('wizard.baseData') ?? true
        )?_c(_setup.FormHelper,{attrs:{"form":_setup.store.getters.form('wizard.baseData'),"config":{
          title: _vm.$t('salesChannelManagementApp.livemode'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...(_setup.labelData ?? {})
        }},on:{"change":function($event){return _setup.formValueChange($event, 'baseData')}},model:{value:(_setup.selectedIntegration.baseData.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.baseData, "value", $$v)},expression:"selectedIntegration.baseData.value"}}):_vm._e(),(_setup.store.getters.form('wizard.credentials'))?_c(_setup.FormHelper,{attrs:{"form":_setup.wizardCredentials,"config":{
          title: _vm.$t('salesChannelManagementApp.credentials'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...(_setup.labelData ?? {})
        }},on:{"change":function($event){return _setup.formValueChange($event, 'credentials')}},model:{value:(_setup.selectedIntegration.credentials.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.credentials, "value", $$v)},expression:"selectedIntegration.credentials.value"}}):_vm._e(),(_setup.store.getters.form('wizard.tbOneChannelSelection'))?_c(_setup.FormHelper,{attrs:{"config":{
          title: _vm.$t('salesChannelManagementApp.titles.tbOneChannelSelection'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...(_setup.labelData ?? {})
        },"form":_setup.store.getters.form('wizard.tbOneChannelSelection')},on:{"change":function($event){return _setup.formValueChange($event, 'tbOneChannelSelection')}},model:{value:(_setup.selectedIntegration.tbOneChannelSelection.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.tbOneChannelSelection, "value", $$v)},expression:"selectedIntegration.tbOneChannelSelection.value"}}):_vm._e(),(_setup.store.getters.form('wizard.sftpData'))?_c(_setup.FormHelper,{attrs:{"config":{
          title: _vm.$t('salesChannelManagementApp.titles.sftpData'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...(_setup.labelData ?? {})
        },"form":_setup.store.getters.form('wizard.sftpData')},on:{"change":function($event){return _setup.formValueChange($event, 'sftpData')}},model:{value:(_setup.selectedIntegration.sftpData.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.sftpData, "value", $$v)},expression:"selectedIntegration.sftpData.value"}}):_vm._e(),(_setup.store.getters.form('wizard.features'))?_c(_setup.FormHelper,{attrs:{"form":_setup.store.getters.form('wizard.features'),"config":{
          title: _vm.$t('salesChannelManagementApp.features'),
          snippetPrefix: 'salesChannelManagementApp',
          labelColClass: 'col-5',
          fieldColClass: 'col-7',
          fieldColClassXl: 'col-7',
          loadingGroup: 'salesChannelManagementApp',
          ...(_setup.labelData ?? {})
        }},on:{"change":event => {
            _setup.onFeaturesChanged(event);
            _setup.formValueChange(event, 'features');
          },"input":_setup.onFeaturesInput},model:{value:(_setup.selectedIntegration.features.value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.features, "value", $$v)},expression:"selectedIntegration.features.value"}}):_vm._e()]:_vm._e(),_vm._t("default")]:_vm._l((_setup.store.getters.form('settings')),function(form,key){return _c(_setup.FormHelper,{key:'advanced-settings-' + key,staticClass:"form-helper-settings",attrs:{"form":form || {},"config":{
        snippetPrefix: 'salesChannelManagementApp',
        title: _vm.$t('salesChannelManagementApp.titles.settings.' + key),
        configValues: false,
        customVariables: ['scmaXentralFields'],
        labelStacked: _vm.selectedArea === 'productAssignment',
        ...(_setup.labelData ?? {}),
        labelColClass: 'col-5',
        fieldColClass: 'col-7',
        fieldColClassXl: 'col-7',
        loadingGroup: 'salesChannelManagementApp'
      }},on:{"change":function($event){return _setup.formValueChange($event, 'settings', key)},"load-formatter":_setup.loadFormatter},model:{value:(_setup.selectedIntegration.settings[key].value),callback:function ($$v) {_vm.$set(_setup.selectedIntegration.settings[key], "value", $$v)},expression:"selectedIntegration.settings[key].value"}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }