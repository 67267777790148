import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "@/core/services/store";
import { useRouter } from "vue-router/composables";
import { useAppLogo } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useAppLogo";
import { Error, Toast } from "@/core/plugins/swal";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Project from "@/components/Settings/Projects/project";
import { UPDATE_SELECTED_PROJECT } from "@/core/services/store/switchArea.module";
import Presets from "@/components/Settings/Presets/presets";
import {
  loadForms,
  loadProjects,
  setSelectedApp,
  setXentralConfig
} from "@/composables/useSalesChannelManagementApp";
import ExternalConnection from "@/components/ExternalApps/SalesChannelManagementApp/classes/externalConnection";
import _ from "lodash";
import Assignments from "@/components/Assignments/assignments";
import { skipFields } from "@/components/Tools/FormHelper/Helper/constants";
import { useMigration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useMigration";
import { useIntegration } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useIntegration";
import AuthValues from "@/components/Settings/Auth/authValues";
import ProcessManager from "@/components/Workflows/processManager";
import { useUtility } from "@/components/ExternalApps/SalesChannelManagementApp/composables/useUtility";
import { labelData } from "@/components/ExternalApps/SalesChannelManagementApp/utility/labelData";

export const useWizard = formComponent => {
  const i18n = getCurrentInstance().proxy.$i18n;
  const router = useRouter();
  const store = useStore();
  const { logo } = useAppLogo();
  const { setMigrationChecksConfigValue } = useMigration();
  const { getIntegrationsWithPresets } = useIntegration();
  const { xentralProjectHeader } = useUtility();

  const currentStep = ref(0);
  const isSaving = ref(false);
  const requests = ref([]);
  const error = ref(false);

  const validCredentials = ref(false);
  const validSftp = ref(false);
  const validName = ref(true);
  const saveFormatters = ref({ saveAssignmentTable });
  const assignmentTableIds = ref([]);
  const baseDataNameBlacklist = ref([]);

  const integration = ref({
    baseData: {},
    credentials: {},
    features: {}
  });
  const defaultValues = ref({});

  const wizardSteps = computed(() => {
    return Object.keys(store.getters.form("wizard") ?? {});
  });

  const step = computed(() => {
    return wizardSteps.value[currentStep.value];
  });

  const form = computed(() => {
    return store.getters.form("wizard." + step.value);
  });

  const formConfig = computed(() => {
    return {
      heading: step.value
        ? i18n.t("salesChannelManagementApp.titles." + step.value)
        : "",
      description: step.value
        ? i18n.t("salesChannelManagementApp.descriptions." + step.value)
        : "",
      snippetPrefix: "salesChannelManagementApp",
      labelStacked: step.value !== "features",
      labelColClass: "col-7",
      fieldColClass: "col-5",
      fieldColClassXl: "col-5",
      ...labelData.value
    };
  });

  const nextButtonDisabled = computed(() => {
    const currentStepKey = wizardSteps.value[currentStep.value];
    const features = integration.value?.features;

    const isFeatureStepInvalid =
      currentStepKey === "features" &&
      (!features || !Object.values(features).some(feature => feature));

    const isBaseDataStepInvalid =
      currentStepKey === "baseData" && !validName.value;

    const isCredentialsStepInvalid =
      currentStepKey === "credentials" && !validCredentials.value;

    const isSftpDataStepInvalid =
      currentStepKey === "sftpData" && !validSftp.value;

    const isLoading =
      store.getters["loadingQueue/showLoadingBar"]("saveWizard") ||
      store.getters["loadingQueue/showLoadingBar"]("validate");

    return (
      isFeatureStepInvalid ||
      isBaseDataStepInvalid ||
      isCredentialsStepInvalid ||
      isSftpDataStepInvalid ||
      isLoading
    );
  });

  function onChange(field) {
    if (["url", "token", "key", "secret"].includes(field.name)) {
      validCredentials.value = false;
      return;
    }

    if (field.name === "name") {
      validName.value =
        !baseDataNameBlacklist.value?.includes(field.value?.trim()) ?? true;
    }
  }

  async function setBaseDataNameBlacklist() {
    addEventToLoadingQueue({ key: "getPresetConfigs" });

    const presetConfigFilter = [
      {
        key: "name",
        op: "equals",
        value: "baseData"
      }
    ];
    const presetConfigs = await Presets.getAll(
      {
        showAll: true,
        noPagination: true
      },
      presetConfigFilter
    );
    const baseDataNameArray = presetConfigs.data
      .map(preset => preset.value?.name)
      .filter(Boolean);
    baseDataNameBlacklist.value = [...new Set(baseDataNameArray)];

    removeEventFromLoadingQueue({ key: "getPresetConfigs" });
  }

  function setIntegrationDefaultValues(ignoreWizard = false) {
    const form = store.getters.form();

    for (const [key, areas] of Object.entries(form)) {
      if (ignoreWizard && key === "wizard") {
        continue;
      }

      if (key === "settings" || key === "migration") {
        defaultValues.value[key] = processAreas(areas);
      } else {
        defaultValues.value = {
          ...defaultValues.value,
          ...processAreas(areas)
        };
      }
    }
  }

  //Loads the old installation progress when loading via the config parameter
  async function getPreviousConfig() {
    const xentralConfigData = store.getters.xentralConfig?.data ?? [];

    if (xentralConfigData["state"] && xentralConfigData["state"].length) {
      const decodedParams = JSON.parse(
        atob(xentralConfigData["state"].replaceAll("%3D", "="))
      );
      let appName = decodedParams.selectedAppName;

      await setSelectedApp(appName);
      await loadForms(appName);
      setIntegrationDefaultValues(true);
      integration.value = decodedParams;
      currentStep.value = 2;
      validCredentials.value = decodedParams.credentials?.success;

      await Toast.fire({
        icon: "success",
        title: i18n.t(`salesChannelManagementApp.${appName}.authSuccess`)
      });
    }
  }

  /**
   * @param {object} area
   * @returns {*}
   */
  function processFieldArray(area) {
    return area.reduce((acc, field) => {
      if (skipFields.includes(field.type)) return acc;
      acc[field.name] = field.default ?? null;
      return acc;
    }, {});
  }

  function processFieldsObject(area) {
    return Object.entries(area).reduce((acc, [fieldsKey, fields]) => {
      acc[fieldsKey] = {
        ...acc[fieldsKey],
        ...processFieldArray(fields)
      };
      return acc;
    }, {});
  }

  function processAreas(areas) {
    return Object.entries(areas).reduce((acc, [areaKey, area]) => {
      acc[areaKey] = {};

      if (Array.isArray(area)) {
        acc[areaKey] = {
          ...acc[areaKey],
          ...processFieldArray(area)
        };
      } else {
        acc[areaKey] = processFieldsObject(area);
      }

      return acc;
    }, {});
  }

  function next() {
    const isValidForm = formComponent.value?.validate() === true;
    const currentStepKey = wizardSteps.value[currentStep.value];

    if (!isValidForm) {
      return;
    }

    if (currentStepKey === "features") {
      const features = integration.value?.features;

      if (!features || !Object.values(features).some(feature => feature)) {
        Toast.fire({
          icon: "warning",
          title: i18n.t("salesChannelManagementApp.featureRequired")
        });
        return;
      }
    }
    if (currentStepKey === "credentials") {
      if (!validCredentials.value) {
        Toast.fire({
          icon: "warning",
          title: i18n.t("salesChannelManagementApp.validateCredentialsError")
        });

        return;
      }
    }

    if (currentStep.value < wizardSteps.value.length - 1) {
      currentStep.value++;
    } else if (currentStep.value === wizardSteps.value.length - 1) {
      saveData();
    }
  }

  function validateCredentials() {
    let selectedAppParams = JSON.parse(
      JSON.stringify(store.getters.appConfig?.additional_params ?? {})
    );

    const credentials = Object.entries(integration.value.credentials).length
      ? integration.value.credentials
      : formComponent.value.credentials.value;

    const params = {
      credentials,
      ...selectedAppParams
    };

    if (selectedAppParams.authType === "skip") {
      validCredentials.value = true;
      return;
    }

    addEventToLoadingQueue({ key: "validateCredentials", group: "validate" });

    ExternalConnection.checkApi(params)
      .then(async response => {
        if (!response.success) {
          Toast.fire({
            icon: "warning",
            title: i18n.t("salesChannelManagementApp.validateCredentialsError")
          });
          return;
        }

        if (selectedAppParams.authType === "oauth1") {
          integration.value.credentials.requestMethod =
            response?.credentials?.requestMethod ?? "header";
        }

        await loadForms({
          sections: ["app", "migration"],
          credentials: integration.value.credentials
        }).then(() => {
          setIntegrationDefaultValues();
          validCredentials.value = true;

          removeEventFromLoadingQueue({
            group: "validateCredentials",
            type: "success",
            prefix: "salesChannelManagementApp",
            name: "validateCredentialsSuccess"
          });
        });
      })
      .finally(() => {
        removeEventFromLoadingQueue({ key: "validateCredentials" });
      });
  }

  function validateSftpData() {
    let params = {
      sftpData: integration.value.sftpData
    };

    addEventToLoadingQueue({ key: "validateSftpData", group: "validate" });

    ExternalConnection.checkSftp(params)
      .then(async response => {
        if (!response.success) {
          await Toast.fire({
            icon: "warning",
            title: i18n.t("salesChannelManagementApp.validateCredentialsError")
          });

          validSftp.value = false;

          return;
        }

        validSftp.value = true;

        removeEventFromLoadingQueue({
          group: "validateSftpData",
          type: "success",
          prefix: "salesChannelManagementApp",
          name: "validateCredentialsSuccess"
        });
      })
      .finally(() => {
        removeEventFromLoadingQueue({ key: "validateSftpData" });
      });
  }

  function back() {
    if (currentStep.value > 0) {
      currentStep.value--;
    }
  }

  async function saveFormatter(
    saveFormatter,
    field,
    value,
    createdIntegration,
    fieldPath = ""
  ) {
    return await saveFormatters.value[saveFormatter](
      field,
      value,
      createdIntegration,
      fieldPath
    );
  }

  async function saveAssignmentTable(
    field,
    value,
    createdIntegration,
    fieldPath
  ) {
    let returnValue = value;
    const assignmentTableName = `${createdIntegration.name}.${fieldPath}.${field.name}`;

    await Assignments.store({
      name: assignmentTableName,
      values: value,
      projects: [createdIntegration.id]
    }).then(response => {
      returnValue = response.data?.id;
      assignmentTableIds.value.push(returnValue);
    });

    return returnValue;
  }

  function resetXentralConfig() {
    //Remove state property from xentralConfig vuex store
    let xentralData = store.getters.xentralConfig;

    // Create a new object excluding the 'state' property
    let newData = { ...xentralData.data };
    delete newData.state;

    let updatedXentralConfig = { ...xentralData, data: newData };

    setXentralConfig(updatedXentralConfig);
  }

  async function saveData() {
    isSaving.value = true;
    const projectName =
      store.getters.selectedApp.name + " " + integration.value.baseData.name;

    addEventToLoadingQueue({ key: "wizardSaveProject", group: "saveWizard" });

    resetXentralConfig();

    // Reset values
    requests.value = [];
    error.value = false;
    assignmentTableIds.value = [];
    const createdProject = ref();

    Project.store({
      name: projectName,
      preset_id: store.getters.presetId
    })
      .then(async response => {
        let project = response.data;
        await store.dispatch(UPDATE_SELECTED_PROJECT, project);
        createdProject.value = project.id;

        storePresetValue(
          "channelIdentifier",
          createdProject,
          createdProject.value,
          "text"
        );

        for (const area of wizardSteps.value) {
          addEventToLoadingQueue({ key: area, group: "saveWizard" });
          if (area === "credentials") {
            storeAuthValue(createdProject, integration.value[area]);
          }
          storePresetValue(area, createdProject, integration.value[area]);

          if (error.value) return;
        }

        for (const settingKey of Object.keys(
          defaultValues.value?.settings ?? {}
        )) {
          const name = "settings" + _.upperFirst(settingKey);
          storePresetValue(
            name,
            createdProject,
            defaultValues.value.settings[settingKey]
          );
        }

        storePresetValue(
          "migration",
          createdProject,
          defaultValues.value.migration
        );

        for (const featureKey of Object.keys(
          integration.value?.features ?? {}
        )) {
          const featureValue = integration.value.features[featureKey];
          if (!featureValue) {
            continue;
          }

          const areas = store.getters.form("app." + featureKey);
          for (const areaKey of Object.keys(areas)) {
            const area = areas[areaKey];
            const name = featureKey + _.upperFirst(areaKey);

            for (const field of Object.values(area)) {
              if (field.saveFormatter) {
                const fieldPath = `${featureKey}.${areaKey}`;

                await saveFormatter(
                  field.saveFormatter,
                  field,
                  defaultValues.value[featureKey][areaKey][field.name] ?? [],
                  store.getters.selectedProject,
                  fieldPath
                ).then(response => {
                  defaultValues.value[featureKey][areaKey][field.name] =
                    response;
                });
              }
            }

            storePresetValue(
              name,
              createdProject,
              defaultValues.value[featureKey][areaKey]
            );

            if (error.value) return;
          }
        }

        await Promise.all(requests.value);
      })
      .catch(() => (error.value = true))
      .finally(async () => {
        const integrationName = projectName
          .replace(store.getters.selectedApp.name, "")
          .trim();
        const notification = {
          type: "success",
          snippet: "wizardSuccess"
        };
        setIntegrationDefaultValues();

        // If an error occurred during saving, we delete every created config value and the created project
        // Then we switch back to the first step
        if (error.value) {
          Error(i18n.t("salesChannelManagementApp.wizardError"));

          let deletions = [];

          // Delete all project specific assignment tables before project
          for (let id of assignmentTableIds.value) {
            deletions.push(Assignments.delete(id));
          }

          await Promise.all(deletions);

          await Project.delete(createdProject.value).then(() => {
            removeEventFromLoadingQueue({
              group: "saveWizard"
            });
            currentStep.value = 0;
          });

          return;
        }

        await setMigrationChecksConfigValue(createdProject.value);

        try {
          addEventToLoadingQueue({
            key: "wizardCreateSalesChannel",
            group: "saveWizard"
          });

          await ProcessManager.run(
            store.getters.createSalesChannelWorkflowId,
            {
              params: {
                channelSpecificName: integrationName,
                projectId: createdProject.value,
                appName: store.getters.selectedApp.name
              }
            },
            xentralProjectHeader.value
          );
        } catch (e) {
          notification.type = "warning";
          notification.snippet = "wizardSalesChannelNotCreated";
        }

        await store.commit("loadSelectedIntegration", {});
        await loadProjects();
        await getIntegrationsWithPresets();

        removeEventFromLoadingQueue({
          group: "saveWizard",
          type: notification.type,
          prefix: "salesChannelManagementApp",
          name: notification.snippet,
          data: {
            integrationName
          }
        });

        isSaving.value = false;

        close();
      });
  }

  /**
   *
   * @param name
   * @param createdProject
   * @param value
   * @param type
   */
  function storePresetValue(name, createdProject, value = [], type = "json") {
    const preset = Presets.store(
      {
        name: name,
        label: name,
        type: type,
        value: value,
        project_id: createdProject.value
      },
      true
    ).catch(() => (error.value = true));
    requests.value.push(preset);
  }

  function storeAuthValue(createdProject, value = []) {
    if (value?.success) {
      return;
    }
    const authValues = AuthValues.store({
      name: "credentials",
      label: "Credentials",
      value: value,
      project_id: createdProject.value
    }).catch(() => (error.value = true));
    requests.value.push(authValues);
  }

  function close() {
    resetXentralConfig();
    router.push({
      name: "salesChannelManagementApp",
      params: {
        name: store.getters.selectedApp.name
      }
    });
  }

  return {
    currentStep,
    form,
    formConfig,
    integration,
    isSaving,
    logo,
    step,
    wizardSteps,
    nextButtonDisabled,
    validName,
    setBaseDataNameBlacklist,
    back,
    close,
    next,
    validateCredentials,
    validateSftpData,
    onChange,
    setIntegrationDefaultValues,
    getPreviousConfig
  };
};
